import React, { useState, useRef } from 'react';
import { Col, Button } from 'antd';
import { CheckCircleTwoTone, SettingFilled, LoadingOutlined, CloseCircleTwoTone } from '@ant-design/icons';
import { isDesktop } from 'react-device-detect';
import { apiGetIpcByUuid } from '../../apis/iot-hub.js';

function FinalDisplay(props) {
  const initOK = useRef(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ reportedStateStatus, setReportedStateStatus ] = useState(null);
  const [ timeoutError, setTimeoutError ] = useState(false);
  const queryIpcStatusCount = useRef(0);

  const widthRatio = isDesktop ? '40%' : '80%';
  const electricMeterRedirectLink = `/electricMeterConfigSetting?sn=${props.currentIpc.sn}`;

  const getIpcStatus = () => {
    // do this at most 20 minutes (3 seconds * 400)
    if( queryIpcStatusCount.current < 400 ) {
      queryIpcStatusCount.current += 1;

      apiGetIpcByUuid(props.currentIpc.uuid).then((res) => {
        if( res.status === 200 ) {
          if( res.data.reported_state !== null ) {
            const statusOk = (res.data.reported_state.status === '200');
            setReportedStateStatus(statusOk);

            if( statusOk === true ) {
              setIsLoading(false);
            } else {
              setTimeout(() => getIpcStatus(), 3000);
            }
          } else {
            setTimeout(() => getIpcStatus(), 3000);
          }
        } else {
          setTimeout(() => getIpcStatus(), 3000);
        }
      }).catch((err) => {
        setTimeout(() => getIpcStatus(), 3000);
      });
    } else {
      if( reportedStateStatus === null ) {
        setTimeoutError(true);
      }
      setIsLoading(false);
    }
  };

  if( initOK.current === false ) {
    initOK.current = true;
    getIpcStatus();
  }

  return (
    !isLoading
    ?
      !timeoutError
      ?
        reportedStateStatus === true
        ?
          props.ipcMachineType === 'meter'
          ?
            <>
              <Col span={ 24 } className="center" style={{ textAlign: 'center' }}>
                { isDesktop
                  ?
                    <div className="success-text"> 此電腦已新增至雲端，請繼續完成設定 </div>
                  :
                    <div className="success-text">
                      此電腦已新增至雲端
                      <br />
                      請繼續完成設定
                    </div>
                }
              </Col>
              <Col span={ 24 } className="center">
                <div style={{ width: widthRatio, height: '80px' }}>
                  <Button
                    type="primary"
                    icon={ <SettingFilled style={{ marginRight: '8px' }} /> }
                    className="full-width-height"
                    style={{ borderRadius: '15px', fontSize: '28px', fontWeight: 700 }}
                    onClick={() => { window.location.href = `${window.location.origin}${electricMeterRedirectLink}`; }}
                  >
                    前往設定電表
                  </Button>
                </div>
               </Col>
            </>
          :
            <>
              <Col span={ 24 } className="center flex-column">
                <div className="success-text"> 此 IPC 裝機流程已完成 </div>
                <div className="success-text"> 且回報狀態正常。 </div>
              </Col>
              <Col span={ 24 } className="center">
                <CheckCircleTwoTone className="success-icon" twoToneColor="#52C41A" />
              </Col>
            </>
        :
          <>
            <Col span={ 24 } className="center flex-column">
              <div className="fail-text"> 此 IPC 裝機流程已完成 </div>
              <div className="fail-text"> 但回報狀態異常，請聯絡管理員排除錯誤。 </div>
            </Col>
            <Col span={ 24 } className="center">
              <CloseCircleTwoTone className="fail-icon" twoToneColor="#FF4D4F" />
            </Col>
          </>
      :
        <>
          <Col span={ 24 } className="center flex-column">
            <div className="fail-text"> 無法取得 IPC 狀態  </div>
            <div className="fail-text"> 請稍後重試，或聯絡管理員排除錯誤。 </div>
          </Col>
          <Col span={ 24 } className="center">
            <CloseCircleTwoTone className="fail-icon" twoToneColor="#FF4D4F" />
          </Col>
        </>
    :
      <>
        <Col span={ 24 } className="center flex-column">
          <div className="start-process-text"> 等待安裝流程結束 </div>
          <div className="start-process-text"> 請耐心等候 </div>
          <LoadingOutlined className="start-process-icon" />
        </Col>
      </>
  );
}

export default FinalDisplay;
