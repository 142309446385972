import React, { useState, useRef } from 'react';
import { Col } from 'antd';
import { LoadingOutlined, CloseCircleTwoTone } from '@ant-design/icons';
import { apiIpcRegister } from '../../apis/iot-hub.js';

function SyncDevices(props) {
  const firstSync = useRef(false);
  const [ syncLoading, setSyncLoading ] = useState(true);

  const syncDevices = () => {
    setSyncLoading(true);

    apiIpcRegister(props.serialNumber, props.tenantId).then((res) => {
      if( res.status === 200 ) {
        props.goNextStep();
      } else {
        setSyncLoading(false);
      }
    }).catch((err) => {
      setSyncLoading(false);
    });
  };

  if( firstSync.current === false ) {
    firstSync.current = true;
    syncDevices();
  }

  return (
    syncLoading
    ?
      <>
        <Col span={ 24 } className="center flex-column">
          <div className="start-process-text"> 正在同步設備 </div>
          <div className="start-process-text"> 請耐心等候 </div>
          <LoadingOutlined className="start-process-icon" />
        </Col>
      </>
    :
      <>
        <Col span={ 24 } className="center flex-column">
          <div className="start-process-text"> 同步設備失敗 </div>
          <div className="start-process-text"> 請稍後重試，或聯絡管理員排除錯誤。 </div>
          <CloseCircleTwoTone className="error-process-icon" twoToneColor="#FF4D4F" />
        </Col>
      </>
  );
}

export default SyncDevices;
