import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import * as queryString from 'query-string';
import { getLoginInfo } from '../../utils.js';
import { apiGetCustomerSet } from '../../apis/user-api.js';
import { apiGetIpcBySerialNumber } from '../../apis/iot-hub.js';
import SelectProcess from './select-process.js';
import InputInfo from './input-info.js';
import SyncDevices from './sync-devices.js';
import FinalDisplay from './final-display.js';
import SwitchTenantModal from './switch-tenant-modal.js';
import DzLogo from '../../assets/dz-logo.png';
import './qrcode-installation.css';

function Register() {
  const [ initOK, setInitOK ] = useState(false);
  const [ getIpcError, setGetIpcError ] = useState(false);
  const [ validParams, setValidParams ] = useState(false);
  const [ serialNumber, setSerialNumber ] = useState('');
  const [ tenantId, setTenantId ] = useState('');
  const [ tenantTitle, setTenantTitle ] = useState('');
  const [ packages, setPackages ] = useState('');
  const [ modules, setModules ] = useState(null);
  const [ customerSet, setCustomerSet ] = useState(null);
  const [ installationStep, setInstallationStep ] = useState(0);
  const [ installationType, setInstallationType ] = useState('');
  const [ currentIpc, setCurrentIpc ] = useState(null);
  const [ switchTenantModalVisible, setSwitchTenantModalVisible ] = useState(false);

  // for different type ipc (nc / meter)
  const [ ipcMachineType, setIpcMachineType ] = useState('');

  useEffect( () => {
    let tmpSerialNumber = '';
    let tmpIpcMachineType = '';
    let tmpPackages = '';
    let tmpModules = null;
    let tmpTenantId = '';
    let tmpTenantTitle = '';
    let tmpValidParams = true;

    // check parameters and login status
    const params = queryString.parse(window.location.search);
    if( params !== null ) {
      // 'sn' is required
      if( params.sn !== undefined && params.sn.trim() !== '' ) {
        tmpSerialNumber = params.sn.trim();
      } else {
        tmpValidParams = false;
      }

      // if 'ipcType' is given, accepted valued: 'nc', 'meter'
      if( params.ipcType !== undefined && params.ipcType.trim() !== '' ) {
        if( params.ipcType.trim() === 'nc' || params.ipcType.trim() === 'meter') {
          tmpIpcMachineType = params.ipcType.trim();
        } else {
          tmpValidParams = false;
        }
      }

      // when 'ipcType' is 'nc' or 'meter', 'packages' cannot be given
      // if 'ipcType' is not given, then 'packages' is required
      if( params.packages !== undefined ) {
        tmpPackages = params.packages.trim();

        if( tmpIpcMachineType !== '' ) {
          tmpValidParams = false;
        }
      } else {
        if( tmpIpcMachineType === '' ) {
          tmpValidParams = false;
        }
      }

      // 'modules' is optional
      if( params.modules !== undefined ) {
        tmpModules = params.modules.trim();
      }
    } else {
      tmpValidParams = false;
    }

    // check login status
    if( tmpValidParams === true ) {
      const loginInfo = getLoginInfo();
      tmpTenantId = loginInfo.user.tenantId;
      tmpTenantTitle = loginInfo.user.tenantId;

      apiGetCustomerSet().then((tmpCustomerSet) => {
        tmpCustomerSet.sort((first, second) => {
          if( first.ID === tmpTenantId ) {
            return -1;
          }

          if( second.ID === tmpTenantId ) {
            return 1;
          }

          return ( first.ID.toLowerCase() > second.ID.toLowerCase() ? 1 : -1 );
        });

        let findCustomer = tmpCustomerSet.find(item => item.ID === tmpTenantId);
        if( findCustomer !== undefined ) {
          tmpTenantTitle = findCustomer.title;
        }

        setCustomerSet(tmpCustomerSet);
      }).catch((err) => {
        // no need to handle errors
      }).then(() => {
        apiGetIpcBySerialNumber(tmpSerialNumber).then((res) => {
          switch(res.status) {
            case 200:
              if( res.data.length === 0 ) {
                setInstallationStep(0);
              } else {
                const ipcInfo = res.data[0];
                if( ipcInfo.reported_state !== null ) {
                  setInstallationStep(3);
                } else {
                  setInstallationStep(2);
                }
                setCurrentIpc(ipcInfo);
              }
              break;
            default:
              setGetIpcError(true);
          }
        }).catch((err) => {
          setGetIpcError(true);
        }).then(() => {
          setTenantId(tmpTenantId);
          setTenantTitle(tmpTenantTitle);
          setSerialNumber(tmpSerialNumber);
          setIpcMachineType(tmpIpcMachineType);
          setPackages(tmpPackages);
          setModules(tmpModules);
          setValidParams(tmpValidParams);
          setInitOK(true);
        });
      });
    } else {
      setValidParams(tmpValidParams);
      setInitOK(true);
    }
  }, []);

  const getInstallationTypeText = () => {
    switch(installationType) {
      case  "production":
        return "正式機安裝";
      case "backup":
        return "備用機安裝";
      default:
        return "";
    }
  };

  const generateDisplayByCurrentStep = () => {
    switch(installationStep) {
      case 0:
        return (
          <SelectProcess
            tenantId={ tenantId }
            customerSet={ customerSet }
            ipcMachineType={ ipcMachineType }
            setInstallationType={ (type) => {
              setInstallationType(type);
            }}
            goNextStep={ () => {
              setInstallationStep((step) => step + 1);
            }}
          />
        );
      case 1:
        return (
          <InputInfo
            tenantId={ tenantId }
            customerSet={ customerSet }
            serialNumber={ serialNumber }
            installationType={ installationType }
            ipcMachineType={ ipcMachineType }
            packages={ packages }
            modules={ modules }
            setCurrentIpc={ (newIpc) => setCurrentIpc(newIpc) }
            goNextStep={ () => {
              setInstallationStep((step) => step + 1);
            }}
          />
        );
      case 2:
        return (
          <SyncDevices
            tenantId={ tenantId }
            serialNumber={ serialNumber }
            currentIpc={ currentIpc }
            goNextStep={ () => {
              setInstallationStep((step) => step + 1);
            }}
          />
        );
      case 3:
        // this means the ipc is already added and registered
        return (
          <FinalDisplay
            tenantId={ tenantId }
            tenantTitle={ tenantTitle }
            currentIpc={ currentIpc }
            ipcMachineType={ ipcMachineType }
          />
        );
      default:
        return null;
    }
  };

  return (
    initOK
    ?
      <div className="full-width-height center">
        <Row gutter={[ 0, 36 ]} justify="center" align="middle">
          <Col span={ 24 } className="center">
            <div className="img-container center">
              <img src={ DzLogo } width="130" alt="dotzero-logo" />
            </div>
          </Col>
          <Col span={ 24 } className="center">
            <div className="title"> QR Code 裝機流程 </div>
          </Col>
          { validParams
            ?
              <>
                <Col span={ 24 } className="center flex-column">
                  <div className="tenant-title center flex-row">
                    { tenantTitle }
                    <div className="swap-tenant center" onClick={ () => { setSwitchTenantModalVisible(true); }}>
                      <SwapOutlined />
                    </div>
                  </div>
                  { tenantTitle !== tenantId
                    ?
                      <div className="tenant-id">
                        ( { tenantId } )
                      </div>
                    :
                      null
                  }
                </Col>
                { installationType !== ''
                  ?
                    <Col span={ 24 } className="center flex-column">
                      <div className="installation-type center"> { getInstallationTypeText() } </div>
                    </Col>
                  :
                    null
                }
              </>
            :
              null
          }
          { validParams
            ?
              !getIpcError
              ?
                generateDisplayByCurrentStep()
              :
                <Col span={ 24 } className="center">
                  <div className="params-error"> 目前無法處理此 IPC 的同步，請稍後再試。 </div>
                </Col>
            :
              <Col span={ 24 } className="center">
                <div className="params-error"> 網址參數有誤，請重新掃描 QR Code 。 </div>
              </Col>
          }
        </Row>
        <SwitchTenantModal
          visible={ switchTenantModalVisible }
          setVisible={ (visible) => setSwitchTenantModalVisible(visible) }
          customerSet={ customerSet }
          currentTenantId={ tenantId }
        />
      </div>
    :
      null
  );
}

export default Register;
